import React from "react";
import Section from "../components/Section/Section";
import Template from "../components/Template/Template";
import Hero from "../components/Hero/Hero";

const Thanks = () => (
  <Template>
    <Section className="bg-bsm-grey-6 top-fill">
      <Hero
        page="thanks"
        largeText={"Thanks! ;-)"}
        smallText="We will get back to you asap"
      />
    </Section>
    <Section className="bg-bsm-primary-white " btrr />
  </Template>
);

export default Thanks;
